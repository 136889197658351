import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserAccount } from '../../lib/models/UserAccount';
import useCurrentUser from '../../pages/hooks/useCurrentUser';
import ModalWrapper from './ModalWrapper';

interface Props {
  visible: boolean;
  user: UserAccount;
  onShare?: () => void;
  onCopyAddress?: () => void;
  onReport?: () => void;
  onClose?: () => void;
}

const ProfileActionsModal = ({
  user,
  visible,
  onShare,
  onCopyAddress,
  onReport,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  const [, isCurrentUser] = useCurrentUser(user);

  return (
    <ModalWrapper visible={visible} onCancel={onClose}>
      <div className="admin-status-card-title">{user.username}</div>
      <div
        role="button"
        tabIndex={0}
        onClick={onCopyAddress}
        onKeyDown={() => null}
        className="my-3 top-trader-name"
      >
        {t('Copy to clipboard')}
      </div>
      <div
        role="button"
        tabIndex={0}
        onClick={onShare}
        onKeyDown={() => null}
        className={`${!isCurrentUser ? 'my-3' : 'mt-3'} top-trader-name`}
      >
        {t('Share')}
      </div>
      {!isCurrentUser && (
        <a
          href={process.env.NEXT_PUBLIC_REPORT_USER_FORM}
          onClick={onReport}
          className="mt-3 top-trader-name text-hover-none"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('Report')}
        </a>
      )}
    </ModalWrapper>
  );
};
export default ProfileActionsModal;
