/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserOfferModel, UserOfferType } from '../../lib/models/NftModel';
import { EmptyIcon } from '../../lib/Resources';
import { UserBidItem } from './UserBidItem';

type Props = {
  createdOffers: UserOfferModel[];
  receivedOffers: UserOfferModel[];
  onFinishAcceptOffer: () => void;
};

const UserBidTab = ({
  createdOffers,
  receivedOffers,
  onFinishAcceptOffer,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="w-full my-3">
      {createdOffers.length > 0 && (
        <div>
          <div className="font-plex-sans-22-black my-2 text-start">
            {t('Created')}
          </div>
          <div>
            {createdOffers.map((bid) => {
              return (
                <UserBidItem
                  bid={bid}
                  type={UserOfferType.CREATED}
                  onFinishAcceptOffer={onFinishAcceptOffer}
                />
              );
            })}
          </div>
        </div>
      )}
      {receivedOffers.length > 0 && (
        <div>
          <div className="font-plex-sans-22-black my-2 text-start">
            {t('Received')}
          </div>
          <div>
            {receivedOffers.map((bid) => {
              return (
                <UserBidItem
                  bid={bid}
                  type={UserOfferType.RECEIVED}
                  onFinishAcceptOffer={onFinishAcceptOffer}
                />
              );
            })}
          </div>
        </div>
      )}
      {!receivedOffers.length && !createdOffers.length && (
        <div className="auction-description-empty-box my-6">
          <div className="empty-box-image">
            <img
              src={EmptyIcon}
              height={40}
              alt="Empty Icon"
              role="presentation"
            />
          </div>
          <p>{t('Your bids will appear here')}</p>
        </div>
      )}
    </div>
  );
};

export default UserBidTab;
