import React, { FC, memo } from 'react';
import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { TermsLink } from '../../lib/Resources';

const { Text } = Typography;

type Props = {
  title?: string;
  className?: string;
  isBanned?: boolean;
  onClick?: () => Promise<void>;
};

const NotFound: FC<Props> = ({
  title,
  isBanned,
  className,
  onClick,
}: Props) => {
  const { t } = useTranslation();

  const onExploreMarket = async () => {
    if (onClick) {
      onClick();
      return;
    }
    await Router.push('/');
  };

  return (
    <div className={className}>
      <div className="nfts-will-appear">{t(title)}</div>
      {(isBanned && (
        <div>
          <br />
          <Text type="warning">
            <a href={TermsLink} target="_blank" rel="noopener noreferrer">
              {t('Violation of terms and services')}
            </a>
          </Text>
        </div>
      )) ||
        null}
      <div className="can-create-or-buy-text">
        {t('You can create or buy some NFTs on marketplace')}
      </div>
      <div className="explore-markets-btn-div">
        <Button
          type="primary"
          className="explore-markets-btn"
          onClick={onExploreMarket}
        >
          <span className="explore-markets-btn-txt">
            {t('Explore Marketplace')}
          </span>
        </Button>
      </div>
    </div>
  );
};

NotFound.defaultProps = {
  title: 'USER NOT FOUND',
  isBanned: false,
  className: 'user-not-found-div',
};

export default memo(NotFound);
