import React, { FC } from 'react';
import { Image } from 'antd';
import SkeletonLoader from './SkeletonLoader';
import { ImageLoadFallBack, NoCoverImg } from '../../lib/Resources';
import UserAvatar from './UserAvatar';
import { UserAccount } from '../../lib/models/UserAccount';

interface Props {
  coverImage?: string;
  profileImage?: string;
  coverClassName?: string;
  avatarClassName?: string;
  user?: UserAccount;
  showVerifiedIcon?: boolean;
}

const CoverProfileCard: FC<Props> = ({
  coverImage,
  profileImage,
  coverClassName,
  avatarClassName,
  user,
  showVerifiedIcon,
}: Props) => (
  <div className="profile-images-wrapper">
    <Image
      src={coverImage}
      alt="Cover image"
      className={coverClassName}
      width="100%"
      fallback={ImageLoadFallBack}
      preview={false}
      placeholder={
        <SkeletonLoader className={`${coverClassName} p-0 static`} />
      }
    />
    <div className="flex justify-center align-center">
      <UserAvatar
        user={user}
        src={profileImage}
        className={avatarClassName}
        imgClassName="user-profile-avatar-img user-avatar-border relative"
        IdenticonSize={100}
        showVerifiedIcon={showVerifiedIcon}
      />
    </div>
  </div>
);

CoverProfileCard.defaultProps = {
  coverClassName: 'profile-cover-img',
  avatarClassName: 'profile-avatar-img',
  coverImage: NoCoverImg,
  showVerifiedIcon: false,
};

export default CoverProfileCard;
