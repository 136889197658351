import { Button, Form, message } from 'antd';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISelectOption } from '../../lib/@Types';
import { NON_SUPPORTED_CHAIN } from '../../lib/messages';
import { MultiNFTDrop, SpecialDropModel } from '../../lib/models/NftModel';
import { backendApiService } from '../../lib/services/BackendApiService';
import { orderObject, sleep } from '../../lib/Utils';
import { connectionInteract } from '../../lib/web3/ConnectionInteract';
import { Web3Service } from '../../lib/web3/Web3Service';
import useCurrentUser from '../../pages/hooks/useCurrentUser';
import { SelectFloatingLabel } from '../FloatingInputLabel';
import ModalWrapper from './ModalWrapper';

type Props = {
  title?: string;
  label?: string;
  buttonText?: string;
  visible?: boolean;
  nftIDs: string[];
  onSubmit?: () => void;
  onClose?: () => void;
};

const AddToExclusiveDrop = ({
  title = 'Add To Drop',
  label = 'Exclusive Drop',
  visible,
  buttonText = 'Apply',
  nftIDs,
  onSubmit,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  const [AddToDropForm] = Form.useForm();
  const [connectionAccount] = useCurrentUser();

  const [saving, setSaving] = useState(false);
  const [collectionData, setCollectionData] = useState<
    Partial<SpecialDropModel>
  >();

  const options: ISelectOption[] = useMemo(() => {
    const opts = [...(connectionAccount.collections || [])].map(
      (collection) => ({
        name: collection.title,
        value: collection.dropID,
        icon: collection.imageUrlThumbnail || collection.imageUrl,
        disabled: false,
      }),
    );
    opts.unshift({
      name: 'Explore ⚡️ (Default)',
      value: 'defaultDropID',
      icon: null,
      disabled: true,
    });

    return opts;
  }, [connectionAccount.collections]);

  const onChange = (value) => {
    const selectedCollection = connectionAccount?.collections.find(
      (item) => item.dropID === value,
    );
    setCollectionData(selectedCollection);
  };

  const onFinish = async () => {
    const { connection } = window;

    setSaving(true);

    const chainID = await connection.eth.getChainId();
    const dropId = AddToDropForm.getFieldValue('drop');

    const web3Service = new Web3Service(connection);

    if (!connectionInteract.getChainById(chainID)) {
      message.warn(t(NON_SUPPORTED_CHAIN, { chainID }), 5);
      setSaving(false);
      return;
    }

    const timestamp = dayjs().format();

    const nftToDrop: MultiNFTDrop = {
      creatorAddress: connectionAccount.address,
      nftIDs,
      timestamp,
    };

    const signResult = await web3Service.signPersonalMessage(
      JSON.stringify(
        orderObject({
          ...nftToDrop,
          signature: undefined,
        }),
      ),
      connectionAccount.address,
    );
    if (!signResult || !signResult.signature) {
      message.warn(t('Error occurred while getting the signature'), 5);
      setSaving(false);
      return;
    }

    nftToDrop.signature = signResult.signature;

    const addNFTSubmission = await backendApiService.addMultipleNFTtoDrop(
      `${dropId}`,
      nftToDrop,
    );

    if (!addNFTSubmission) {
      setSaving(false);

      message.warn(
        t('Failed to add NFTs to {{collection}}', {
          collection: collectionData?.title,
        }),
        3,
      );
      return;
    }

    message.success(
      t('{{size}} NFTs added to {{collection}} successfully', {
        collection: collectionData?.title,
        size: nftIDs.length,
      }),
      4,
    );

    setSaving(false);

    sleep(1_000);
    onSubmit?.();
  };

  return (
    <ModalWrapper visible={visible} onCancel={onClose}>
      <div className="account-modal-div">
        <div className="transfer-nft-title">{t(title)}</div>
        <Form form={AddToDropForm} onFinish={onFinish}>
          <SelectFloatingLabel
            label={label}
            value={AddToDropForm.getFieldValue('drop')}
            name="drop"
            initialValue="defaultDropID"
            options={options}
            className="floating-form-item"
            imgClassName="select-trailing-image shadow-xs"
            onChange={onChange}
          />
          <Button
            type="primary"
            htmlType="submit"
            block
            className="nft-card-edit-button"
            loading={saving}
            disabled={connectionAccount.collections?.length <= 0}
          >
            <span className="nft-create-drop-button-text">{t(buttonText)}</span>
          </Button>
        </Form>
      </div>
    </ModalWrapper>
  );
};

export default AddToExclusiveDrop;
