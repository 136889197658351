import React from 'react';
import { useRecoilValue } from 'recoil';
import useResearchResults from '../../pages/hooks/useResearchResults';
import { ExploreTitle } from './ExploreTitle';
import SearchResultsView from './SearchResultsView';
import { searchModeState } from '../../recoil/atoms';

const SearchResultPage = () => {
  const inSearchMode = useRecoilValue(searchModeState);

  const onLoadMoreSearchResults = useResearchResults();

  return (
    <div className="nft-container">
      <ExploreTitle inSearchMode={inSearchMode} />
      <SearchResultsView onLoadMoreSearchResults={onLoadMoreSearchResults} />
    </div>
  );
};

export default SearchResultPage;
