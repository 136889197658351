import React, { FC } from 'react';

interface Props {
  url: string;
  icon: string;
  alt: string;
  className?: string;
}

const isValidURL = (url) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  );
  return !!pattern.test(url);
};

const SocialMediaIcon: FC<Props> = ({ url, icon, alt, className }: Props) => {
  if (!url) return null;
  if (!isValidURL(url)) return null;

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      key={`${url}_${alt}`}
      className={`account-social-link-icon ${className}`}
    >
      <img src={icon} alt={alt} height={17} width={17} />
    </a>
  );
};

SocialMediaIcon.defaultProps = {
  className: 'social-icon shadow-xxs',
};

export default SocialMediaIcon;
