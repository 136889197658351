import React, { useEffect, useCallback } from 'react';
import { Empty, Modal, Skeleton, Tabs } from 'antd';
import Link from 'next/link';

import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { UserAccount } from '../../lib/models/UserAccount';
import { CloseActionBtn } from '../../lib/Resources';
import { middleEllipsis } from '../../lib/Utils';
import UserAvatar from './UserAvatar';
import { backendApiService } from '../../lib/services/BackendApiService';
import { accountUsersState } from '../../recoil/atoms';
import { UserFollower as IUserFollower } from '../../lib/models/GeneralModels';

const { TabPane } = Tabs;

const UserFollower = ({
  user,
  onClick,
}: {
  user: UserAccount;
  onClick?: () => void;
}) => (
  <Link href={`/creators/${user.walletAddress}`}>
    <div className="user-follower" role="presentation" onClick={onClick}>
      <div className="user-follower__avatar">
        <UserAvatar
          IdenticonSize={38}
          user={user}
          className="user-avatar-container relative"
        />
      </div>
      <div className="user-follower__name top-trader-name">
        {user.username || middleEllipsis(user?.walletAddress, 4)}
      </div>
    </div>
  </Link>
);

export const UserFollowersList = ({
  user,
  onClose,
  selectedTab = 'followers',
}: {
  user: Partial<UserAccount>;
  onClose?: () => void;
  selectedTab?: 'followers' | 'followings' | null;
}) => {
  const { t } = useTranslation();

  const [{ followers, followings, loading }, setAccountUsers] = useRecoilState(
    accountUsersState,
  );

  const loadFollowers = useCallback(async () => {
    const type = selectedTab;
    setAccountUsers((users) => ({
      ...users,
      loading: true,
    }));

    const res = await backendApiService.loadUserFollowers({
      walletAddress: user.walletAddress,
      type,
    });

    switch (type) {
      case 'followers':
        setAccountUsers((users) => ({
          ...users,
          followers: res?.followers || [],
        }));
        break;
      case 'followings':
        setAccountUsers((users) => ({
          ...users,
          followings: res?.followings || [],
        }));
        break;
      default:
        break;
    }
    setAccountUsers((users) => ({
      ...users,
      loading: false,
    }));
  }, [selectedTab]);

  useEffect(() => {
    loadFollowers();
  }, [loadFollowers]);

  useEffect(
    () => () => {
      setAccountUsers({ loading: false, followers: [], followings: [] });
    },
    [],
  );

  const callback = (key) => {
    switch (key) {
      case 'followers':
        if (followers.length === 0) {
          loadFollowers();
        }
        break;
      case 'followings':
        if (followings.length === 0) {
          loadFollowers();
        }
        break;
      default:
        loadFollowers();
        break;
    }
  };

  const TabContent = ({
    userKey,
    description,
    users,
  }: {
    userKey: 'follower' | 'following';
    users: IUserFollower[];
    description: string;
  }) => (
    <>
      {loading && <Skeleton avatar paragraph={{ rows: 1 }} active />}
      {users.length === 0 && !loading ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t(description)}
          className="auction-description"
        />
      ) : null}
      {users.map((item) => (
        <UserFollower
          key={item[userKey].id}
          user={item[userKey]}
          onClick={onClose}
        />
      ))}
    </>
  );

  return (
    <Modal
      title=""
      visible={!!selectedTab}
      centered
      onCancel={onClose}
      footer={null}
      className="user-followers-modal"
      closeIcon={
        <img src={CloseActionBtn} alt="Close action" height={20} width={20} />
      }
    >
      <div className="followers__title">
        {user?.username || middleEllipsis(user.walletAddress, 4)}
      </div>
      <Tabs defaultActiveKey={selectedTab} onChange={callback} className="pb-3">
        <TabPane tab={t('FOLLOWERS')} key="followers">
          <TabContent
            description="Followers will appear here"
            userKey="follower"
            users={followers}
          />
        </TabPane>
        <TabPane tab={t('FOLLOWING')} key="followings">
          <TabContent
            description="Following will appear here"
            userKey="following"
            users={followings}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};
