/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Image, message } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Router, { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';
import { UserOfferModel, UserOfferType } from '../../lib/models/NftModel';
import {
  BNBIcon,
  ClockIcon,
  ImageLoadFallBack,
  OpenLinkIconV2,
} from '../../lib/Resources';
import { CHAINS } from '../../lib/constants';
import { calculateOfferTimeLeft } from '../../lib/Utils';
import HrefLink from './HrefLink';
import { connectionAccountState } from '../../recoil/atoms';
import { Web3Service } from '../../lib/web3/Web3Service';
import { storageService } from '../../lib/db/StorageService';
import { connectionInteract } from '../../lib/web3/ConnectionInteract';
import { configService } from '../../lib/services/ConfigService';

type Props = {
  bid: UserOfferModel;
  type: UserOfferType;
  onFinishAcceptOffer: () => void;
};

export const UserBidItem = ({ bid, type, onFinishAcceptOffer }: Props) => {
  const currentAccount = useRecoilValue(connectionAccountState);

  const [fcmToken, setFcmToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState('');
  const timer = useRef(null);

  const { t } = useTranslation();
  const { push } = useRouter();

  const chainAsset = CHAINS[bid.nft.chain] || CHAINS.bsc;

  const hasOfferExpired = dayjs(bid.offerEndedAt).isBefore(dayjs());

  const txUrl = `${configService.getExplorerUrl(bid.nft.chain)}tx/`;

  useEffect(() => {
    setFcmToken(
      localStorage.getItem(
        `_an_fcm_token_${currentAccount.address}`.toLowerCase(),
      ),
    );
  }, [currentAccount.address]);

  useEffect(() => {
    const launchpadTimeLeft = calculateOfferTimeLeft(bid.offerEndedAt);
    setTimeLeft(launchpadTimeLeft.timeLeft);

    timer.current = setInterval(() => {
      setTimeLeft(calculateOfferTimeLeft(bid.offerEndedAt).timeLeft);
    }, 1000);

    // Clear timeout if the component is unmounted
    return () => clearInterval(timer.current);
  });

  const openNft = () => {
    if (bid?.nft.baseID) {
      push(`/nft/${bid.nft.baseID}`);
    }
  };

  async function onUserProfileClicked(string: string) {
    await Router.push(`/creators/${string}`);
  }

  const onCancelOffer = async () => {
    if (!window.connection || !window.ethereum) {
      message.warn(
        t('Please make sure you are using a Web3 compatible browser'),
        3,
      );
      return;
    }

    setLoading(true);

    if (currentAccount.chain !== bid.nft.chain) {
      await connectionInteract.switchChain(bid.nft.chain);
      setLoading(false);
      return;
    }

    const { connection } = window;
    const web3Service = new Web3Service(connection);

    const cancelOffer = await web3Service.cancelOffer({
      listingID: +bid.listingID,
      offerorAddress: bid.buyerAddress,
      chain: bid.nft.chain,
    });

    if (cancelOffer) {
      const offerCancelling = await storageService.cancelOffer({
        buyerAddress: bid.buyerAddress,
        listingID: +bid.listingID,
        offerId: +bid.id,
        offerTrxHash: cancelOffer.offerTrxHash,
        fcmToken: fcmToken || '',
        baseID: bid.nft.baseID,
        chain: bid.nft.chain,
      });

      if (!offerCancelling) {
        message.error('Failed to cancel offer');
        setLoading(false);
        return;
      }

      message.success('Offer cancelled');
    } else {
      message.error(t('Please try later'), 3);
      setLoading(false);
      return;
    }

    setLoading(false);
    onFinishAcceptOffer();
  };

  const onAcceptOffer = async () => {
    if (!window.connection || !window.ethereum) {
      message.warn(
        t('Please make sure you are using a Web3 compatible browser'),
        3,
      );
      return;
    }

    setLoading(true);

    if (currentAccount.chain !== bid.nft.chain) {
      await connectionInteract.switchChain(bid.nft.chain);
      setLoading(false);
      return;
    }

    if (currentAccount.address !== bid.nft.ownerAddress) {
      message.warn(t('Only the owner can accept an offer'), 3);
      setLoading(false);
      return;
    }

    const { connection } = window;
    const web3Service = new Web3Service(connection);

    const offerAcceptance = await web3Service.acceptOffer({
      listingID: +bid.listingID,
      ownerAddress: bid.sellerAddress,
      offerorAddress: bid.buyerAddress,
      chain: bid.nft.chain,
    });

    if (offerAcceptance) {
      const acceptOffer = await storageService.acceptOffer({
        buyerAddress: bid.buyerAddress,
        sellerAddress: bid.nft.ownerAddress,
        listingID: offerAcceptance.listingID,
        offerId: +bid.id,
        offerTrxHash: offerAcceptance.offerTrxHash,
        fcmToken: fcmToken || '',
        price: bid.price,
        chain: bid.nft.chain,
        baseID: bid.nft.baseID,
      });

      if (!acceptOffer) {
        message.error('Failed to accept offer');
        setLoading(false);
        return;
      }

      message.success('Offer accepted');
    } else {
      message.error(t('Please try later'), 3);
      setLoading(false);
      return;
    }

    setLoading(false);
    onFinishAcceptOffer();

    await onUserProfileClicked(bid.buyerAddress);
  };

  function getAcceptOfferButton() {
    if (hasOfferExpired) {
      return (
        <div
          className="flex justify-center align-center"
          role="button"
          style={{ marginRight: '10px' }}
        >
          <a
            href={txUrl + bid.offerTrxHash}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={OpenLinkIconV2}
              width={22}
              height={22}
              className="cursor-pointer"
              alt="OpenLink icon"
            />
          </a>
        </div>
      );
    }

    return (
      <div
        className="flex justify-center align-center"
        role="button"
        style={{ paddingRight: '10px' }}
      >
        <Button
          type="primary"
          className="bid-item-button flex justify-center align-center"
          onClick={onAcceptOffer}
          block
          loading={loading}
        >
          {loading ? t('Accepting') : t('Accept')}
        </Button>
      </div>
    );
  }

  return (
    <div className="user-bid-item">
      <HrefLink
        href={`/nft/${bid.nft.baseID}`}
        className="user-bid-item-link"
        onClick={openNft}
      >
        <div className="flex justify-start align-center">
          <div className="collection-item-img" style={{ marginLeft: '10px' }}>
            <Image
              src={bid.nft.url || bid.nft.urlCompressed}
              alt={`${bid.nft.name} Image`}
              fallback={ImageLoadFallBack}
              width={44}
              height={44}
              // preview={false}
            />
          </div>
          <div style={{ marginLeft: '10px' }}>
            <div className="flex justify-start align-center">
              <div className="normal-text-15">Bid {bid.price}</div>
              <img
                src={chainAsset.icon || BNBIcon}
                width={20}
                height={20}
                alt="Chain Asset logo"
                style={{ marginLeft: '5px' }}
              />
            </div>
            <div className="bid-item-time" style={{ marginTop: '2px' }}>
              <div className="nft-card-detail-offer-timer">
                <img src={ClockIcon} alt="Clock icon" />
                {hasOfferExpired ? 'Expired' : `Ends in: ${timeLeft}`}
              </div>
            </div>
          </div>
        </div>
      </HrefLink>
      {type === 'created' ? (
        <div
          className="flex justify-center align-center"
          role="button"
          style={{ paddingRight: '10px' }}
        >
          <Button
            type="primary"
            className="bid-item-button-cancel flex justify-center align-center"
            onClick={onCancelOffer}
            block
            loading={loading}
          >
            {loading ? t('Canceling...') : t('Cancel')}
          </Button>
        </div>
      ) : (
        getAcceptOfferButton()
      )}
    </div>
  );
};
