import { Button, Col, Row } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterIcon } from '../../lib/Resources';

interface IProps {
  title?: string;
  query: string;
  canSelect?: boolean;
  shouldAdd?: boolean;
  selectable?: boolean;
  onFilter?: (query) => void;
  onSelect?: () => void;
  onAdd?: () => void;
  onCancel?: () => void;
}

const FilterItemTile = ({
  title = 'Explore',
  query,
  canSelect,
  shouldAdd,
  selectable,
  onFilter,
  onAdd,
  onCancel,
  onSelect,
}: IProps) => {
  const { t } = useTranslation();

  const [shouldSelect, setShouldSelect] = useState(canSelect);

  const onClickSelect = () => {
    setShouldSelect(true);

    onSelect?.();
  };

  const onClickCancel = () => {
    setShouldSelect(false);

    onCancel?.();
  };

  return (
    <div className="flex flex-wrap align-center justify-between w-full my-3">
      <div className="font-plex-sans-22-black">{t(title)}</div>

      <Row gutter={[8, 8]} wrap={false}>
        {selectable && (
          <>
            {shouldSelect ? (
              <>
                <Col>
                  <Button
                    type="default"
                    className={`nft-card-edit-button h-40 shadow-xs normal-text-15 text-gray border-transparent m-0 ${
                      shouldAdd ? 'bg-blue text-white' : 'bg-white'
                    }`}
                    onClick={onAdd}
                    block
                  >
                    {t('Add')}
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="default"
                    className="nft-card-edit-button h-40 bg-white shadow-xs normal-text-15 text-gray border-transparent m-0"
                    onClick={onClickCancel}
                    block
                  >
                    {t('Cancel')}
                  </Button>
                </Col>
              </>
            ) : (
              <Col>
                <Button
                  type="default"
                  className="nft-card-edit-button h-40 bg-white shadow-xs normal-text-15 text-gray border-transparent m-0"
                  onClick={onClickSelect}
                  block
                >
                  {t('Select')}
                </Button>
              </Col>
            )}
          </>
        )}
        <Col className="hidden-div">
          <div
            key="filter"
            onClick={() => onFilter?.(query)}
            onKeyDown={() => null}
            role="button"
            tabIndex={-120}
          >
            <div className="nft-category-text">
              <img
                src={FilterIcon}
                alt="Filter Icon"
                height={40}
                width={40}
                role="presentation"
                className=""
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FilterItemTile;
