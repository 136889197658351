import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { UserAccount } from '../../lib/models/UserAccount';
import { connectionAccountState } from '../../recoil/atoms';
import { ConnectionAccount } from '../../recoil/models';

const useCurrentUser = (
  user?: UserAccount | string,
): [ConnectionAccount, boolean] => {
  const connectedAccountUser = useRecoilValue(connectionAccountState);

  const [isCurrentUser, setIsCurrentUser] = useState(false);

  useEffect(() => {
    const userAddress = typeof user === 'string' ? user : user?.walletAddress;
    const isCurrent =
      connectedAccountUser &&
      connectedAccountUser.address &&
      connectedAccountUser.address.toLowerCase() === userAddress?.toLowerCase();

    setIsCurrentUser(isCurrent);
  }, [connectedAccountUser, user]);

  return [connectedAccountUser, isCurrentUser];
};

export default useCurrentUser;
