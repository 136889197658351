import { Button, Col, message, Row } from 'antd';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMoreHorizontal } from 'react-icons/fi';
import copy from 'copy-to-clipboard';
import Router from 'next/router';
import moment from 'moment';
import { UserAccount } from '../../lib/models/UserAccount';
import {
  CopyActionBtn,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
} from '../../lib/Resources';
import { configService } from '../../lib/services/ConfigService';
import {
  formatNumber,
  middleEllipsis,
  orderObject,
  truncate,
} from '../../lib/Utils';
import { UserLoad } from '../../recoil/models';
import CoverProfileCard from './CoverProfileCard';
import ProfileActionsModal from './ProfileActionsModal';
import SocialMediaIcon from './SocialMediaIcon';
import UserAvatar from './UserAvatar';
import useCurrentUser from '../../pages/hooks/useCurrentUser';
import { storageService } from '../../lib/db/StorageService';
import { IFollowSignature } from '../../lib/@Types';
import { Web3Service } from '../../lib/web3/Web3Service';
import { VolumeTradedUSD } from './VolumeTradedUSD';

type TModalControler = {
  showFilterModal: boolean;
  showActionsModal: boolean;
  showExclusiveModal: boolean;
};

type Props = {
  metaTitle: string;
  user: UserAccount;
  modalController: TModalControler;
  setModalController: React.Dispatch<React.SetStateAction<TModalControler>>;
  setFollowersModal: React.Dispatch<
    React.SetStateAction<'followers' | 'followings'>
  >;
  children: JSX.Element;
};

const ProfileUserDetails = ({
  user,
  metaTitle,
  modalController,
  children,
  setModalController,
  setFollowersModal,
}: Props) => {
  const { t } = useTranslation();

  const [connectedAccountUser, isCurrentUser] = useCurrentUser(user);
  const [isPerforming, setIsPerforming] = useState(false);

  const [profileUser, setProfileUser] = useState<UserLoad>({
    user,
    hasLoaded: true,
  });

  const isMounted = useRef(true);

  const onActionsClick = () => {
    setModalController((modalState) => ({
      ...modalState,
      showActionsModal: !modalState.showActionsModal,
    }));
  };

  const onEditClicked = useCallback(async () => {
    await Router.push('/creators/edit');
  }, []);

  const onAddressCopyClicked = useCallback(async () => {
    copy(profileUser.user.walletAddress);
    await message.info(t('Address copied to clip board'), 3);
  }, [profileUser.user.walletAddress]);

  const shareProfile = () => {
    const url = `${configService.getAppUrl()}creators/${
      user.username || user.walletAddress
    }`;

    if (navigator.share) {
      const userBio = profileUser.user.userBio || t('AirNFTs artist');
      navigator
        .share({
          title: metaTitle,
          text: userBio,
          url,
        })
        .then(() => {})
        .catch(() => {
          copy(url);
          message.success(t('Creator profile URL was copied 🎨'));
        });
    } else {
      copy(url);
      message.success(t('Creator profile URL was copied 🎨'));
    }
  };

  const getPayloadAndSignature = async (
    warning = 'Could not get signature for following the user',
  ): Promise<IFollowSignature | null> => {
    const { connection } = window;
    const web3Service = new Web3Service(connection);
    const payload: IFollowSignature = {
      timestamp: moment().format(),
      walletAddress: connectedAccountUser.address,
    };

    const result = await web3Service.signPersonalMessage(
      JSON.stringify(orderObject(payload)),
      connectedAccountUser.address,
    );

    if (!result.signature) {
      message.warn(t(warning));
      setIsPerforming(false);
      return null;
    }

    return {
      ...payload,
      signature: result.signature,
    };
  };

  const onFollowUser = useCallback(async () => {
    setIsPerforming(true);

    const followerUser = { ...profileUser.user };

    const payload = await getPayloadAndSignature();

    if (payload.signature) {
      const response = await storageService.followUser(
        profileUser?.user?.walletAddress,
        payload,
      );
      if (!response) {
        setIsPerforming(false);
        return;
      }

      if (response && isMounted.current) {
        setIsPerforming(false);
        followerUser.userFollowed = true;
        followerUser.followerCount += 1;
        setProfileUser((profile) => ({ ...profile, user: followerUser }));
      }
    } else {
      setIsPerforming(false);
    }
  }, [profileUser.user.walletAddress]);

  const onUnFollowUser = useCallback(async () => {
    setIsPerforming(true);
    const unFollowedUser = { ...profileUser.user };

    const payload = await getPayloadAndSignature(
      'Could not get signature for unfollowing the user',
    );

    if (payload.signature) {
      const response = await storageService.unFollowUser(
        profileUser.user.walletAddress,
        payload,
      );

      if (!response && isMounted.current) {
        setIsPerforming(false);
        return;
      }

      if (response && isMounted.current) {
        setIsPerforming(false);

        unFollowedUser.userFollowed = false;
        unFollowedUser.followerCount -= 1;
        setProfileUser((profile) => ({ ...profile, user: unFollowedUser }));
      }
    } else {
      setIsPerforming(false);
    }
  }, [profileUser.user.walletAddress]);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );

  useEffect(() => {
    setProfileUser({ user, hasLoaded: true });
  }, [user]);

  return (
    <>
      <div className="user-profile-avatar-div">
        {user.coverUrl ? (
          <div className="user-profile-container">
            <CoverProfileCard
              profileImage={user.userAvatarUrl}
              coverImage={user?.coverThumbnailUrl || user?.coverUrl}
              coverClassName="user-profile-cover-img"
              user={user}
            />
          </div>
        ) : (
          <div
            className="user-profile-badge-container"
            style={{ paddingTop: '30px' }}
          >
            <UserAvatar
              user={user}
              className="user-profile-avatar-bg"
              imgClassName="user-profile-avatar-img"
              IdenticonSize={100}
            />
          </div>
        )}
      </div>
      <div className="user-profile-username font-plex-sans-22-black">
        {user.username && !user.banned ? user.username : t('CREATOR')}
      </div>
      <div
        className="user-profile-account-section"
        onClick={onAddressCopyClicked}
        onKeyDown={onAddressCopyClicked}
        role="button"
        tabIndex={-12}
      >
        <span className="user-profile-address-text">
          {middleEllipsis(user.walletAddress, 8)}{' '}
        </span>
        <span>
          <img
            src={CopyActionBtn}
            alt="Copy Icon"
            height={16}
            width={16}
            className="account-copy-address-icon"
          />
        </span>
      </div>
      <div className="user-profile-bio-div">
        {user.userBio && !user.banned ? truncate(user.userBio, 300) : ''}
      </div>

      <div className="flex justify-center">
        <Row className="stats-wrapper sm:justify-evenly" gutter={[12, 24]}>
          <Col
            lg={6}
            md={6}
            sm={6}
            xs={12}
            role="button"
            onClick={() => setFollowersModal('followers')}
          >
            <span className="drop-detail-item-volume text-18">
              {profileUser.user.followerCount}
            </span>
            <span className="drop-detail-item-value">{t('FOLLOWERS')}</span>
          </Col>
          <Col
            lg={6}
            md={6}
            sm={6}
            xs={12}
            role="button"
            onClick={() => setFollowersModal('followings')}
          >
            <span className="drop-detail-item-volume text-18">
              {profileUser.user.followingCount}
            </span>
            <span className="drop-detail-item-value">{t('FOLLOWING')}</span>
          </Col>
          <Col lg={6} md={6} sm={6} xs={12}>
            <div className="flex justify-center align-center">
              <VolumeTradedUSD
                amount={profileUser?.user.USDSalesTotalAmount}
                className="drop-detail-item-volume text-18"
              />
            </div>
            <span className="drop-detail-item-value">{t('VOL TRADED')}</span>
          </Col>

          <Col lg={6} md={6} sm={6} xs={12}>
            <span className="drop-detail-item-volume text-18">
              {formatNumber(profileUser.user.nftsCreatedCount || 0, 1)}
            </span>
            <span className="drop-detail-item-value">{t('MINTED')}</span>
          </Col>
        </Row>
      </div>

      <div className="profile-edit-button-div">
        {isCurrentUser && (
          <Button
            className="user-profile-profile-btn shadow-xxs account-social-link-icon"
            onClick={onEditClicked}
          >
            {t('Edit Profile')}
          </Button>
        )}
        {!isCurrentUser &&
          connectedAccountUser.address &&
          !profileUser.user.userFollowed && (
            <Button
              className="user-profile-profile-btn follow-user-btn account-social-link-icon"
              onClick={onFollowUser}
              disabled={isPerforming}
            >
              {t('Follow')}
            </Button>
          )}
        {!isCurrentUser &&
          connectedAccountUser.address &&
          profileUser.user.userFollowed && (
            <Button
              className="user-profile-profile-btn shadow-xxs account-social-link-icon"
              onClick={onUnFollowUser}
            >
              {t('Unfollow')}
            </Button>
          )}
        {!profileUser.user.banned && (
          <>
            <SocialMediaIcon
              url={profileUser.user.twitterUrl}
              icon={TwitterIcon}
              alt="Twitter Icon"
            />
            <SocialMediaIcon
              url={profileUser.user.facebookUrl}
              icon={FacebookIcon}
              alt="Facebook Icon"
            />
            <SocialMediaIcon
              url={profileUser.user.instagramUrl}
              icon={InstagramIcon}
              alt="Instagram Icon"
            />
          </>
        )}
        <div
          className="social-icon shadow-xxs user-profile-share-icon account-social-link-icon"
          onClick={onActionsClick}
          onKeyDown={() => null}
          tabIndex={-133}
          role="presentation"
        >
          <FiMoreHorizontal size={25} className="text-gray" />
        </div>
      </div>

      <div className="current-user-nfts-lists">{children}</div>
      <ProfileActionsModal
        visible={modalController.showActionsModal}
        user={profileUser.user}
        onClose={onActionsClick}
        onShare={shareProfile}
        onCopyAddress={shareProfile}
      />
    </>
  );
};

export default memo(ProfileUserDetails);
