import Router, { useRouter } from 'next/router';
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { List, message, Spin, Tabs } from 'antd';

import { useTranslation } from 'react-i18next';
import {
  connectionAccountState,
  currentlySelectedCreatorTabState,
  currentUserExternalNftState,
  searchModeState,
  smallDeviceSearchTriggerState,
} from '../../recoil/atoms';
import { middleEllipsis } from '../../lib/Utils';
import { NftCard } from '../../components/NftCard';
import { storageService } from '../../lib/db/StorageService';
import { UserLoad } from '../../recoil/models';
import { UserAccount } from '../../lib/models/UserAccount';
import {
  NftCardData,
  NftModel,
  NftModelToCardData,
  NftSaleToCardData,
  UserGroupedNfts,
} from '../../lib/models/NftModel';
import { PageMetadata } from '../../components/ui/PageMetadata';
import cookieParser from '../../lib/cookieParser';
import ListViewBuilder from '../../components/ListViewBuilder';
import { IExternalNFT, IFilters, IListViewBuilder } from '../../lib/@Types';
import { UserFollowersList } from '../../components/ui/UserFollowersList';
import FilterItemTile from '../../components/ui/FilterItemTile';
import { FilterModal } from '../../components/ui/FilterModal';
import AddToExclusiveDrop from '../../components/ui/AddToExclusiveDrop';
import NotFound from '../../components/ui/NotFound';
import ProfileUserDetails from '../../components/ui/ProfileUserDetails';
import SearchResultPage from '../../components/ui/SearchResultPage';
import UserBidTab from '../../components/ui/UserBidTab';

const { TabPane } = Tabs;

interface IFilterNFT {
  query?: string;
  selectable?: boolean;
}

const UserDetailPage = ({
  userNfts,
  currentUser,
  externalNfts,
}: {
  userNfts: UserGroupedNfts;
  externalNfts: IExternalNFT[];
  currentUser: UserAccount;
}) => {
  const { t } = useTranslation();
  const connectedAccountUser = useRecoilValue(connectionAccountState);
  const [currentTab, setCurrentlySelectedCreatorTab] = useRecoilState(
    currentlySelectedCreatorTabState,
  );
  const [{ externalNfts: currentUserExtNfts }] = useRecoilState(
    currentUserExternalNftState,
  );

  const [userNftGroup, setUserNftGroup] = useState<UserGroupedNfts>(userNfts);

  const [profileUser, setProfileUser] = useState<UserLoad>({
    user: currentUser,
    hasLoaded: true,
  });

  const inSearchMode = useRecoilValue(searchModeState);
  const smallDeviceSearchTrigger = useRecoilValue(
    smallDeviceSearchTriggerState,
  );

  const { tab } = useRouter().query;

  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [currentLoadLimit, setCurrentLoadLimit] = useState(12);
  const [isFiltering, setIsFiltering] = useState(false);
  const [loadingExternalNfts] = useState(false);
  const [followersModal, setFollowersModal] = useState<
    'followers' | 'followings' | null
  >(null);

  const [modalController, setModalController] = useState({
    showFilterModal: false,
    showActionsModal: false,
    showExclusiveModal: false,
  });

  const [tabController, setTabController] = useState<
    Record<
      string,
      {
        shouldSelect: boolean;
        selectedNfts: string[];
        filters: IFilters;
      }
    >
  >({});

  const isCurrentUser =
    connectedAccountUser?.address?.toLowerCase() ===
    profileUser?.user?.walletAddress?.toLowerCase();

  useEffect(() => {
    setProfileUser({ user: currentUser, hasLoaded: true });
    setUserNftGroup(userNfts);
  }, [currentUser, userNfts]);

  async function onLoadMore() {
    setFetchMoreLoading(true);

    const newLoadLimit = currentLoadLimit + 8;
    const loadedMoreNfts = await storageService.getUserNftGrouped(
      profileUser.user.walletAddress,
      newLoadLimit,
    );

    const hasLoadedAll =
      userNftGroup.currentNfts.length >= loadedMoreNfts.currentNfts.length &&
      userNftGroup.soldNfts.length >= loadedMoreNfts.soldNfts.length &&
      userNftGroup.boughtNfts.length >= loadedMoreNfts.boughtNfts.length &&
      userNftGroup.createdNfts.length >= loadedMoreNfts.createdNfts.length;

    setHasMore(hasLoadedAll);

    if (hasLoadedAll) {
      message.warning(t('You have loaded all items'));
      setFetchMoreLoading(false);
      setShowLoadMore(false);
    }

    if (loadedMoreNfts) {
      setUserNftGroup(loadedMoreNfts);
      setCurrentLoadLimit(newLoadLimit);
      window.dispatchEvent(new Event('resize'));
    }

    setFetchMoreLoading(false);
  }

  const onSelectNft = (nftData: NftCardData) => {
    const tabData = tabController[currentTab];
    const { selectedNfts } = tabData;
    let allSelectedNfts = [...(selectedNfts || [])];

    const nftIndex = allSelectedNfts.findIndex(
      (selected) => selected === nftData.nft.id,
    );

    if (nftIndex < 0) {
      allSelectedNfts.push(nftData.nft.id);
    } else {
      allSelectedNfts = allSelectedNfts.filter(
        (selected) => selected !== nftData.nft.id,
      );
    }

    setTabController((state) => ({
      ...state,
      [currentTab]: {
        ...tabData,
        selectedNfts: allSelectedNfts,
      },
    }));
  };

  const renderItem = (item) => (
    <List.Item>
      <NftCard
        nftCardData={item}
        hideUserAvatar
        selectable={
          !item.nft?.dropId &&
          !item.nft.isExternal &&
          tabController[currentTab]?.shouldSelect &&
          isCurrentUser
        }
        selectionPosition={
          tabController[currentTab]?.selectedNfts?.indexOf(item.nft?.id) + 1
        }
        onSelect={onSelectNft}
      />
    </List.Item>
  );

  const CreatorListView = ({
    items,
    showEndMessage,
    renderItem: render,
    loading = false,
    converter = NftModelToCardData,
    shouldConvert = true,
  }: Partial<IListViewBuilder>) => {
    const itemsData = useMemo(() => items, [items]);

    return (
      <ListViewBuilder
        hasMore={!hasMore}
        items={itemsData}
        showLoadMoreButton={showLoadMore}
        showEndMessage={showEndMessage}
        shouldConvert={shouldConvert}
        loading={loading}
        loadingMore={fetchMoreLoading}
        renderItem={render || renderItem}
        converter={converter}
        onLoadMore={onLoadMore}
      />
    );
  };

  const onFilterClick = () => {
    setModalController((modals) => ({ ...modals, showFilterModal: true }));
  };

  const onAddSelectedNfts = () => {
    if (!tabController[currentTab]?.selectedNfts?.length) {
      message.warning(t('Select at least one NFT'));
      return;
    }

    setModalController((modalState) => ({
      ...modalState,
      showExclusiveModal: true,
    }));
  };

  const clearSelectedNfts = () => {
    const tabData = tabController[currentTab];

    setTabController((state) => ({
      ...state,
      [currentTab]: {
        ...tabData,
        selectedNfts: [],
        shouldSelect: false,
      },
    }));
    setModalController((modalState) => ({
      ...modalState,
      showExclusiveModal: false,
    }));
  };

  const onToggleExclusiveModal = () => {
    setModalController((modalState) => ({
      ...modalState,
      showExclusiveModal: !modalState.showExclusiveModal,
    }));
  };

  const onToggleSelect = () => {
    const tabData = tabController[currentTab];

    setTabController((state) => ({
      ...state,
      [currentTab]: {
        ...tabData,
        shouldSelect: !tabData?.shouldSelect,
      },
    }));
  };

  const onCancelSelect = () => {
    const tabData = tabController[currentTab];

    setTabController((state) => ({
      ...state,
      [currentTab]: {
        ...tabData,
        selectedNfts: [],
        shouldSelect: false,
      },
    }));
  };

  const updateUserData = async () => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const [userNfts] = await Promise.all([
      storageService.getUserNftGrouped(connectedAccountUser.address, 50),
    ]);

    if (userNfts) {
      setUserNftGroup(userNfts);
    } else {
      Router.reload();
    }
  };

  const FilterNft: FC<IFilterNFT> = ({ query, selectable }: IFilterNFT) => (
    <FilterItemTile
      query={query}
      title={
        tabController[currentTab]?.shouldSelect ? `${t('Selected')}` : 'Explore'
      }
      selectable={selectable && isCurrentUser}
      shouldAdd={!!tabController[currentTab]?.selectedNfts?.length}
      canSelect={tabController[currentTab]?.shouldSelect}
      onAdd={onAddSelectedNfts}
      onFilter={onFilterClick}
      onSelect={onToggleSelect}
      onCancel={onCancelSelect}
    />
  );

  const CreatorMenu = memo(() => {
    if (
      userNftGroup &&
      userNftGroup.boughtNfts.length === 0 &&
      userNftGroup.createdNfts.length === 0 &&
      userNftGroup.soldNfts.length === 0 &&
      userNftGroup.currentNfts.length === 0 &&
      externalNfts?.length === 0 &&
      !isCurrentUser
    ) {
      return <NotFound title="NFTs will appear here" className="" />;
    }

    function onActiveKeyChanged(activeKey) {
      setCurrentlySelectedCreatorTab(activeKey);
    }

    const onFilter = async (sorterQuery) => {
      const tabData = tabController[currentTab];
      setIsFiltering(true);
      const sortedUserNfts = await storageService.getUserNftGrouped(
        currentUser.walletAddress,
      );

      if (sortedUserNfts) {
        setUserNftGroup(sortedUserNfts);
      }

      setTabController((state) => ({
        ...state,
        [currentTab]: {
          ...tabData,
          filters: sorterQuery,
        },
      }));
      setIsFiltering(false);
    };

    const specialDrops = [];
    userNftGroup?.specialDrops?.forEach((drop) => {
      // Only show drops publicly if the creator has published
      if (
        drop.creatorAddress === connectedAccountUser.address ||
        drop.published
      ) {
        specialDrops.push(drop);
      }
    });

    return (
      <div className="creator-menu">
        <Tabs
          defaultActiveKey={tab ? (tab as string) : currentTab}
          centered
          onChange={onActiveKeyChanged}
        >
          <TabPane
            tab={t('INVENTORY')}
            key="current"
            className="creator-menu-pane"
          >
            <FilterNft query="collection" selectable />
            <CreatorListView
              items={[
                ...(currentUserExtNfts as NftModel[]),
                ...userNftGroup.currentNfts,
              ]}
              shouldConvert
              loading={loadingExternalNfts || isFiltering}
            />
          </TabPane>

          <TabPane tab={t('CREATED')} key="created">
            <FilterNft query="created" selectable />
            <CreatorListView
              items={userNftGroup.createdNfts}
              shouldConvert
              showEndMessage={userNftGroup.createdNfts.length > 12 && !hasMore}
            />
          </TabPane>

          <TabPane tab={t('SOLD')} key="sold">
            <FilterNft query="sold" selectable />
            <CreatorListView
              items={userNftGroup.soldNfts}
              shouldConvert
              converter={NftSaleToCardData}
              showEndMessage={userNftGroup.soldNfts.length > 12 && !hasMore}
            />
          </TabPane>

          {isCurrentUser && (
            <TabPane tab={t('BIDS')} key="bids">
              <UserBidTab
                createdOffers={userNftGroup.createdOffers}
                receivedOffers={userNftGroup.receivedOffers}
                onFinishAcceptOffer={updateUserData}
              />
            </TabPane>
          )}

          {/* Hide momentarily */}
          {/* {currentUser.canCreateDrop && !currentUser.notAllowedToMint ? (
            <TabPane tab={t('DROPS')} key="drops">
              <FilterNft query="drops" />
              <CreatorListView
                items={specialDrops}
                loading={false}
                shouldConvert={false}
                renderItem={(item) => (
                  <List.Item>
                    <DropCardListItem
                      dropCardData={SpecialDropToDropCardData(
                        item as SpecialDropModel,
                      )}
                    />
                  </List.Item>
                )}
                showEndMessage={specialDrops.length > 12 && !hasMore}
              />
            </TabPane>
          ) : (
            <div />
          )} */}
        </Tabs>
        <div style={{ height: '50px' }} />
        <FilterModal
          visible={modalController.showFilterModal}
          filters={tabController[currentTab]?.filters}
          onCancel={() =>
            setModalController((modal) => ({
              ...modal,
              showFilterModal: false,
            }))
          }
          onFilter={onFilter}
        />
        <AddToExclusiveDrop
          visible={modalController.showExclusiveModal}
          onClose={onToggleExclusiveModal}
          nftIDs={tabController[currentTab]?.selectedNfts}
          onSubmit={clearSelectedNfts}
        />
      </div>
    );
  });

  const LowerComponent = memo(() => {
    if (!profileUser.hasLoaded) {
      return (
        <div className="home-init-spinner">
          <Spin size="large" />
        </div>
      );
    }
    if (currentUser.banned) {
      return <NotFound title="USER WAS BANNED" isBanned />;
    }
    return <CreatorMenu />;
  });

  const getMetaTitle = useCallback(
    () =>
      profileUser?.user?.username ||
      t('CREATOR #{{walletAddress}} - Collection', {
        walletAddress: middleEllipsis(profileUser.user.walletAddress, 4),
      }),
    [profileUser?.user?.username],
  );

  const WhenLoadingOrFailure = () =>
    !profileUser.hasLoaded ? (
      <Spin spinning className="home-init-spinner" />
    ) : (
      <NotFound />
    );

  if (inSearchMode && smallDeviceSearchTrigger) {
    return <SearchResultPage />;
  }

  return (
    <div className="nft-container">
      {followersModal && (
        <UserFollowersList
          onClose={() => setFollowersModal(null)}
          selectedTab={followersModal}
          user={profileUser.user}
        />
      )}
      {profileUser.hasLoaded && profileUser.user ? (
        <>
          <PageMetadata
            MetaTitle={getMetaTitle()}
            MetaImageUrl={
              profileUser.user?.userAvatarUrlCompressed ||
              profileUser.user?.userAvatarUrl
            }
            MetDescription={profileUser.user.userBio}
          />
          <ProfileUserDetails
            metaTitle={getMetaTitle()}
            user={profileUser.user}
            modalController={modalController}
            setModalController={setModalController}
            setFollowersModal={setFollowersModal}
          >
            <LowerComponent />
          </ProfileUserDetails>
        </>
      ) : (
        WhenLoadingOrFailure()
      )}
    </div>
  );
};

export async function getServerSideProps(context) {
  const { slug } = context.params;
  const cookies = cookieParser(context?.req);
  const headers = {
    address: cookies._anft_user_address || '',
  };

  const [userNfts] = await Promise.all([
    storageService.getUserNftGrouped(slug, 50, headers),
  ]);

  return {
    props: {
      currentUser: userNfts?.user || null,
      userNfts: userNfts || [],
    },
  };
}

export default UserDetailPage;
